<template>
  <div class="UserInfo">

    <v-img :src='require("@/assets/imgs/my_bg.png")'>
          <!-- 有token显示个人资料 -->
    <div class="topbar">
      <img @click="$router.push('/')" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
    </div>
    </v-img>
    <div class="item-list">
      <div class="content">
        <img :src='require("@/assets/imgs/person.png")' alt="" class="logo"/>
        <div class="account">{{userInfo.account}}</div>
        <div class="total mx-3">
          <div
            class="d-flex justify-space-between align-center"
            style="width: 100%"
          >
            <div class="total-left">
              <p class="pa-0 ma-0">{{ $t("總資產") }}</p>
              <h3 class="pa-0 ma-0">
                {{ userInfo.point }}<span class="ml-2">{{ $t("點") }}</span>
              </h3>
              <!-- <h3 class="pa-0 ma-0">
                {{
                  userInfo.point +
                  userInfo.point_twd +
                  userInfo.point_krw +
                  userInfo.point_usd
                }}<span class="ml-2">{{ $t("點") }}</span>
              </h3> -->
            </div>
            <div class="btn-deposit" @click="$router.push('/Recharge')">
              {{ $t("充值") }}
              <img :src='require("@/assets/imgs/arrow.png")'/>
            </div>
          </div>
        </div>
        <!-- 钱包 -->
        <!-- <div class="wallet">
          <div class="wallet-box">
            <div class="sp1">{{ $t("人民幣錢包") }}</div>
            <div class="sp2">{{ userInfo.point }}</div>
          </div>
          <div class="wallet-box">
            <div class="sp1">{{ $t("臺幣錢包") }}</div>
            <div class="sp2">{{ userInfo.point_twd }}</div>
          </div>
          <div class="wallet-box">
            <div class="sp1">{{ $t("韓幣錢包") }}</div>
            <div class="sp2">{{ userInfo.point_krw }}</div>
          </div>
          <div class="wallet-box">
            <div class="sp1">{{ $t("美元錢包") }}</div>
            <div class="sp2">{{ userInfo.point_usd }}</div>
          </div>
        </div> -->

        <div class="three-list">
          <ul>
            <li @click="OrderFind()">
              <div class="item">
                <img :src="imgIcon1" alt="" />
                {{ $t("點數查詢") }}
              </div>
            </li>
            <li @click="$router.push('/CooperateUs')">
              <div class="item">
                <img :src="imgIcon2" alt="" />
                {{ $t("聯繫客服") }}
              </div>
            </li>
            <li @click="Mydetails()">
              <div class="item">
                <img :src="imgIcon3" alt="" />
                {{ $t("我的憑證") }}
              </div>
            </li>
            <li @click="$router.push('/ChangePassword')">
              <div class="item">
                <img :src="imgIcon4" alt="" />
                {{ $t("修改密碼") }}
              </div>
            </li>
            <li @click="$router.push('/booking')">
              <div class="item">
                <img :src="imgIcon5" alt="" />
                {{ $t("預約新游") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 用户信息
      userInfo: [],
      img: require("@/assets/imgs/image1.jpg"),
      imgIcon1: require("@/assets/imgs/select.png"),
      imgIcon2: require("@/assets/imgs/message.png"),
      imgIcon3: require("@/assets/imgs/myInfo.png"),
      imgIcon4: require("@/assets/imgs/changePassword.png"),
      imgIcon5: require("@/assets/imgs/icon_book.png"),
    };
  },
  created() {
    // 登录拿到个人信息
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.$get("/api/Home/UserInfo").then((res) => {
        this.userInfo = res.data;
      });
    },
    // 跳转我的凭证
    Mydetails() {
      this.$router.push({
        name: "AccountCode",
        params: {
          account: this.userInfo.account,
          key: this.userInfo.key,
        },
      });
    },
    // 点数查询
    OrderFind() {
      this.$router.push("/Order");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application ul, .v-application ol{
  padding-left:0;
}
.v-application ol, .v-application ul{
  padding-left:0;
}
// 个人信息
.UserInfo {
  background: url("~@/assets/imgs/userInfo-bgc.jpg") fixed no-repeat;
  background-size: 100% 100%;
  .topbar {
    height: 1.17333rem;
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .logo{
    width: 2.948rem;
    background: #F0F6F9;
    border: 0.08rem solid #F0F6F9;
    box-shadow: 0 0.32rem 0.53333rem #d7e4ea;
    border-radius: 2.66667rem;
    margin-top: -1.48rem;
  }
  .account{
    font-size: 0.53333rem;
    letter-spacing: -0.00747rem;
    color: #333333;
    margin-bottom: 13px;
    margin-top: -.28rem;

  }
  .item-list {
    background: rgba(255, 255, 255, 0.65);
backdrop-filter: blur(25px);
/* Note: backdrop-filter has minimal browser support */

border-radius: 60px 60px 0px 0px;
    position: absolute;
    top: 180px;
    left: 0;
    right: 0;
    bottom: 0;

    .content {
      .total {
        background: #F84E84;
    box-shadow: 0 0.4rem 0.53333rem rgb(245 86 136 / 10%);
    border-radius: 0.26667rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .14rem 0.34667rem;
    
    .btn-deposit{
      font-size: 18px;
line-height: 25px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.28px;
color: #5C4825;

      background: linear-gradient(90deg, #F2D093 -19.39%, #FBE1AF 105.14%);
box-shadow: 0px 4px 15px #D82D63;
border-radius: 20px;
padding: 7px 30px;
img{
  width: 12px;
height: 12px;
margin-left: 5px;
}
    }

        .total-left {
          text-align: left;
          p {
            font-size: 0.37333rem;
    color: #fff;
          }
          h3 {
            font-size: 0.8rem;
    font-weight: 700;
    color: #fff;
            span {
              display: inline-block;
    font-size: 0.37333rem;
    font-weight: 400;
            }
          }
        }
        .v-btn {
          font-size: 16px;
          color: #5c4825;
          width: 115px;
          height: 40px;
          background: linear-gradient(90deg, #f2d093 -19.39%, #fbe1af 105.14%);

          box-shadow: 0px 4px 15px #d82d63;
          border-radius: 20px;
        }
        .tips {
          width: 100%;
          font-size: 16px;
          color: #fff;
          text-align: left;
        }
      }
      .wallet {
        margin: -30px 10.5px 0 10.5px;
        padding: 10px 20px;
        background: linear-gradient(91.69deg, #1a1a1a 16.39%, #595959 139.39%);
        display: flex;
        align-items: center;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .wallet-box {
          width: 25%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          &::after {
            content: "";
            width: 1px;
            height: 15px;
            background-color: #7e7e7e;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
          &:last-child::after {
            content: none;
          }

          .sp1 {
            font-size: 12px;
            color: #999999;
          }
          .sp2 {
            font-size: 12px;
            color: #cccccc;
          }
        }
      }
      .three-list {
        
        backdrop-filter: blur(50px);
        margin-top: 0px;
        border-radius: 20px;
      
        ul {
          display: flex;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 26px;

          li {
   width: 30%;
    margin-left: 3.3%;
    margin-bottom: 10px;
            .item {
              display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 0.37333rem;
    line-height: 0.4rem;
    color: #485573;
    width: 100px;
    height: 100px;
    background: #F0F6F9;
    border-radius: 10px;
              
            img {
              width: 30px;
              margin-bottom: 14px;
            }
            }
          }
        }
      }
    }
  }
}
</style>